export class Lightbox {
    constructor() {
        this.lightbox = document.querySelector('[data-lightbox]')
        this.container = document.querySelector('.s-cards-archive')
        this.els = document.querySelectorAll('.s-cards-archive--type-s .c-card-archive')

        if (this.els.length) {
            this.init()
            this.close()
        }
    }

    init() {
        this.els.forEach(el => {
            if (el.querySelector('.c-btn')) {
                el.querySelector('.c-btn').addEventListener('click', (e) => {
                    if (this.container.getAttribute('data-lightbox-active') === 'lightbox' && e.target.href.includes('#')) {
                        e.preventDefault();

                        let type = el.getAttribute('data-lightbox-type')

                        if (type === "image") {
                            let img = el.querySelector('img').cloneNode(true)

                            this.lightbox.querySelector('.lightbox-content').appendChild(img)
                        }

                        if (type === "video") {
                            let iframe = document.createElement('iframe')
                            iframe.setAttribute('width', '100%')
                            iframe.setAttribute('height', '100%')
                            iframe.setAttribute('allowFullScreen', 'allowFullScreen')
                            iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share')
                            iframe.setAttribute('src', el.getAttribute('data-lightbox-video'))

                            this.lightbox.querySelector('.lightbox-content').appendChild(iframe)
                        }

                        this.lightbox.classList.add('active')
                    }
                })
            }
        })
    }

    close() {
        this.lightbox.querySelector('.lightbox-close').addEventListener('click', () => {
            this.lightbox.classList.remove('active')
            this.lightbox.querySelector('.lightbox-content').innerHTML = ''
        })
    }
}
