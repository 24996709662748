import Swiper from 'swiper';
import {Autoplay, Pagination} from "swiper/modules";

export class Sliders {
	constructor() {
		this.sliders = document.querySelectorAll('.swiper');

		if (this.sliders.length) {
			this.init();
		}
	}

	init() {
		this.sliders.forEach(slider => {
			const swiper = new Swiper(slider, {
				slidesPerView: 1,
				spaceBetween: 0,
				centeredSlides: true,
				modules: [Pagination, Autoplay],
				initialSlide: 1,
				pagination: {
					el: slider.querySelector('.' + slider.getAttribute('data-block-id')),
					clickable: true,
				},
				autoplay: {
					delay: 3000,
				},
				breakpoints: {
					1200: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
					800: {
						slidesPerView: 1,
						spaceBetween: slider.getAttribute('data-spaceBetween') ?? 118,
					}
				}
			});
		});
	}
}
