export class FloatingBar {
	constructor() {

		this.helper = document.querySelector('#c-helper');
		this.floatingbar = document.querySelector('.c-floating-bar');

		if (this.helper) {
			this.init();
		}
	}

	init() {
		this.helper.addEventListener('click', () => {
			this.helper.classList.toggle('active');
			this.floatingbar.classList.toggle('active');
		})
	}
}
